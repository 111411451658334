import React from 'react';
import ReactDOM from 'react-dom';

class Form extends React.Component
{
    constructor(props)
    {
        super(props);

        this.state = {
			sent: false
		}

        this.fields = props.fields;
        this.types = props.types;
        this.form = props.form;
        this.token = props.token;
        this.url = "https://api.bulldog.dev/v1/form/" + this.form + "/";
        this.handleSubmit = this.handleSubmit.bind(this);
    }

    render()
    {
        if(this.state.sent) {
            return (
                <div className="form-thank-you">
                    {this.props.complete}
                </div>
            )
        }
        let placeholdersProp = "";
        if(this.props.placeholders) {
            placeholdersProp = this.props.placeholders;
        }
        let fields = this.props.fields.split(",");
        let types = this.props.types.split(",");
        let labels = this.props.labels.split(",");
        let placeholders = placeholdersProp.split(",");
        let inputs = fields.map((v, i) => {
            switch(types[i]) {
                case "textarea":
                    return (
                        <div key={i}>
                            <label htmlFor={"el" + i}>{labels[i]}</label>
                            <textarea id={"el" + i} name={v} placeholder={placeholders[i]} required></textarea>
                        </div>
                    )
                case "email":
                    return (
                        <div key={i}>
                            <label htmlFor={"el" + i}>{labels[i]}</label>
                            <input id={"el" + i} type="email" name={v} placeholder={placeholders[i]} required></input>
                        </div>
                    )
                default:
                    return (
                        <div key={i}>
                            <label htmlFor={"el" + i}>{labels[i]}</label>
                            <input id={"el" + i} type="text" name={v} placeholder={placeholders[i]} required></input>
                        </div>
                    )
            }
        })

        return (
            <div className="form-container">
                <form onSubmit={this.handleSubmit}>
                    {inputs}
                    <div>
                        <button>Submit</button>
                    </div>
                    <input type="hidden" name="api_token" value={this.token}></input>
                </form>
            </div>
        );
    }

    handleSubmit(event) {
        event.preventDefault();
        const data = new FormData(event.target);

        fetch(this.url, {
          method: 'POST',
          body: data,
        }).then((r) => {
            this.setState({sent:true});
        });

      }
}

document.addEventListener("DOMContentLoaded", function() {
	let root = document.getElementById('form');
	if(root) {
		ReactDOM.render(<Form {...(root.dataset)} />, root);
	}
});
